import React from 'react';
import { useState } from 'react';
import { useRouter } from 'next/router';
import { MdArrowRightAlt } from 'react-icons/md';
import { useSupabaseClient } from '@supabase/auth-helpers-react';

import {
  useStateWithValidation,
  emailRegex,
} from '@utilities/frontend/useStateWithValidation';
import Spinner from '@components/common/spinner';
import Button from '@components/common/button';
import Link from '@components/common/link';
import { Input } from '@components/common/form';
import SessionFormContainer from '@components/session/session-form-container';
import SignInPlaceholder from '@components/session/sign-in-placeholder';
import { createServerClient } from '@utilities/backend/supabase';

const LoginPage = () => {
  const router = useRouter();

  const [email, setEmail, valid] = useStateWithValidation('', {
    regex: emailRegex,
  });
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [magicLinkSent, setMagicLinkSent] = useState(false);

  const updateEmail = ({ target }: any) => setEmail(target.value.trim());
  const updatePassword = ({ target }: any) => setPassword(target.value.trim());

  const supabaseClient = useSupabaseClient();

  const submitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    if (password) {
      const { data, error } = await supabaseClient.auth.signInWithPassword({
        email,
        password,
      });

      if (error) {
        // This error isn't very user-friendly, so let's make it nicer
        let message = error.message.replace(
          'Signups not allowed for otp',
          "It looks like you don't have an account with that email yet! Head over to the sign up link below to get started.",
        );

        if (message === 'Invalid login credentials') {
          message =
            'Email or password are incorrect. Please try again or click forgot password.';
        }

        if (message === 'Email not confirmed') {
          const { error, ...res } = await supabaseClient.auth.resend({
            type: 'signup',
            email,
          });
          setError(
            `Your account is not verified. We've sent you a new verification link. Please, verify and login again`,
          );
          setLoading(false);
          return;
        }

        setError(message);
      } else if (!data.user) {
        let message =
          "It looks like you don't have an account with that email yet! Head over to the sign up link below to get started.";
        setError(message);
      } else {
        // TODO: ENABLE when will be available CRUD service for sending notifications
        // if user hasn't login for a long period of time
        // await supabaseClient
        //   .from('users')
        //   .update({
        //     month_sign_in_notification_sent_at: null,
        //     two_months_sign_in_notification_sent_at: null,
        //   })
        //   .eq('id', data.user.id)
        //   .single();
        router.push('/');
        // router.push('/', undefined, { shallow: true });
      }
    } else {
      const { error } = await supabaseClient.auth.signInWithOtp({
        email,
        options: {
          shouldCreateUser: false,
          emailRedirectTo: `${process.env.NEXT_PUBLIC_VERCEL_URL}/auth`,
        },
      });

      if (error) {
        // This error isn't very user-friendly, so let's make it nicer
        const message = error.message.replace(
          'Signups not allowed for otp',
          "It looks like you don't have an account with that email yet! Head over to the sign up link below to get started.",
        );
        setError(message);
      } else {
        setMagicLinkSent(true);
        setTimeout(() => {
          setMagicLinkSent(false);
        }, 5000);
      }
    }

    setLoading(false);
  };

  return (
    <>
      <div className="w-full xl:min-h-screen new-sing-in flex flex-col xl:flex-row xl:columns-2">
        <SignInPlaceholder />

        <SessionFormContainer>
          <div
            className="
                  content-box  w-full bg-white rounded-xl
                  mt-[60px] p-[20px] mb-[60px]
                  md:p-[40px] md:w-[445px]
                  lg:bg-white xl:mt-0 xl:mb-0"
          >
            <h1
              className="
                    font-primary text-30 mb-6
                    text-center text-dark-dark1
                    xl:text-48
                  "
            >
              Welcome back!
            </h1>

            <p
              className="
              text-center text-dark-dark2
              text-14 font-secondary w-[300px]
              md:mx-auto md:w-[330px]
            "
            >
              Sign into your account to access Selfmade with your password or
              send yourself a magic link.
            </p>

            {!magicLinkSent && (
              <form onSubmit={submitForm}>
                <Input
                  name="email"
                  label=""
                  type="email"
                  className="mt-6 bg-grey-input h-[49px]"
                  inputClassName="bg-grey-input text-12"
                  containerClassName="border-transparent mt-0"
                  placeholder="Enter your email address"
                  autoComplete="email"
                  value={email}
                  onChange={updateEmail}
                />

                <Input
                  name="password"
                  label=""
                  type="password"
                  className="mt-[20px] bg-grey-input h-[49px]"
                  inputClassName="bg-grey-input text-12"
                  containerClassName="border-transparent mt-0"
                  placeholder="Enter your password"
                  autoComplete="current-password"
                  value={password}
                  onChange={updatePassword}
                />

                <div className="flex items-center justify-between my-[20px]">
                  <div className="flex items-center">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      className="h-4 w-4 rounded text-14 font-secondary custom-color-success"
                    />
                    <label
                      htmlFor="remember-me"
                      className="ml-2 block text-14 font-secondary text-dark-dark2"
                    >
                      Remember me
                    </label>
                  </div>

                  <div className="text-sm">
                    <Link
                      href="/reset-password"
                      className="text-14 font-secondary color-royal-blue"
                    >
                      Forgot password
                    </Link>
                  </div>
                </div>

                <Button
                  size="lg"
                  className="w-full transition-all !bg-yellow-main rounded !text-dark-dark1"
                  disabled={!valid || loading}
                >
                  {loading ? (
                    <>
                      Loading... <Spinner />
                    </>
                  ) : password ? (
                    <>
                      Sign In <MdArrowRightAlt />
                    </>
                  ) : (
                    <>
                      Send Sign In Link <MdArrowRightAlt />
                    </>
                  )}
                </Button>

                {!!error && (
                  <p className="font-secondary text-14 font-semibold mt-5 mb-3 text-center text-warning">
                    {error}
                  </p>
                )}
              </form>
            )}

            {magicLinkSent && (
              <div className="lg:bg-white p-4 md:p-5 md:border border-grey-light w-full mx-auto md:w-[330px]">
                <p className="font-secondary text-18 font-semibold mb-3">
                  Check your email!
                </p>
                <p className="font-secondary text-14 font-semibold mb-3">
                  We&apos;ve sent your sign in link to {email}.
                </p>
                <p className="font-secondary text-14">
                  This link allows you to access Selfmade password-free. The
                  link expires shortly, so please sign in soon. If you
                  haven&apos;t received an email from us with your sign in link
                  after five minutes, try again.
                </p>
              </div>
            )}

            <p className="text-14 font-secondary mt-[25px] mb-[5px] w-full max-w-[544px] text-center">
              Don&apos;t have an account yet? {/* /signup */}
              <Link href="/signup" className="inline-flex text-burnt-orange">
                Get started
              </Link>
            </p>
          </div>
        </SessionFormContainer>
      </div>
    </>
  );
};

export default LoginPage;

export async function getServerSideProps({ req, res }: any) {
  const supabaseServerClient = createServerClient(req, res);
  const {
    data: { user },
  } = await supabaseServerClient.auth.getUser();

  if (user) {
    return { props: {}, redirect: { destination: '/' } };
  }

  return { props: {} };
}
